<div class="spinner" *ngIf="spinner">
    <mat-spinner diameter="100"></mat-spinner>
</div>
<div style="float: right;cursor: pointer;">
    <button class="deleteIcon" mat-dialog-close> <i class="fas fa-times"></i></button>
</div>
<h2 mat-dialog-title>Edit Guest</h2>
<hr>
<div class="container">
    <form [formGroup]="preRegisterForm" (ngSubmit)="onUpdatePreRegister()">
        <mat-dialog-content class="mat-typography">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Full name*</mat-label>
                <input type="text" matInput placeholder="Enter recipient" formControlName="fullName">
            </mat-form-field>
            <div *ngIf="preRegisterForm.controls.fullName.touched && preRegisterForm.controls.fullName.invalid">
                <div class="text-danger" *ngIf="preRegisterForm.controls.fullName.errors.required">
                    Full name is required.
                </div>
                <div *ngIf="preRegisterForm.get('fullName').touched && (preRegisterForm.get('fullName').errors?.pattern )"
                    class="text-danger">Full name accepts only alphabets</div>
                <div class="text-danger"
                    *ngIf="preRegisterForm.controls.fullName.errors.minlength || preRegisterForm.controls.fullName.errors.maxlength">
                    <p style="font-size: 14px;">
                        Full name accepts between 3-50 alphabets
                    </p>
                </div>
            </div>

            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Comapny name*</mat-label>
                <input type="text" class="companyname" matInput placeholder="Company name"
                    formControlName="companyName">
            </mat-form-field>
            <div *ngIf="preRegisterForm.controls.companyName.touched && preRegisterForm.controls.companyName.invalid">
                <div class="text-danger" *ngIf="preRegisterForm.controls.companyName.errors.required">
                    Company name is required.
                </div>
                <div *ngIf="preRegisterForm.get('companyName').touched && (preRegisterForm.get('companyName').errors?.pattern )"
                    class="text-danger">This is invalid company name</div>
                <div class="text-danger"
                    *ngIf="preRegisterForm.controls.companyName.errors.minlength || preRegisterForm.controls.companyName.errors.maxlength">
                    <p style="font-size: 14px;">
                        Company name accepts between 2-50 characters
                    </p>
                </div>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Location</mat-label>
                <mat-select formControlName="location" (selectionChange)="getSelectedEmp($event)">
                    <mat-option *ngFor="let location of deviceLocation" value="{{location.locationId}}" selected>
                        {{location.officeName}}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- <mat-form-field appearance="outline">
        <mat-label>Location</mat-label>
        <mat-select disableRipple formControlName="location">
          <mat-option *ngFor="let location of deviceLocation" value="{{location.locationId}}" selected>
            {{location.officeName}}</mat-option>
        </mat-select>
      </mat-form-field> -->

            <mat-form-field appearance="outline" style="width: 100%;">
                <mat-label>Visiting(Host)</mat-label>
                <mat-select matNativeControl formControlName="HostName" required>
                    <mat-option *ngFor="let employee of employees" value="{{employee.fullName}}" selected>
                        {{employee?.fullName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="mb-4">
                <mat-label>Dates Range*</mat-label>
                <mat-date-range-input [rangePicker]="picker" [min]="minDate">
                    <input matStartDate placeholder="Start date" readonly formControlName="dateOfVisit">
                    <input matEndDate placeholder="End date" readonly formControlName="dateOut">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="preRegisterForm.controls.dateOfVisit.hasError('matStartDateInvalid')">Invalid start
                    date</mat-error>
                <mat-error *ngIf="preRegisterForm.controls.dateOut.hasError('matEndDateInvalid')">Invalid end date
                </mat-error>

                <mat-error *ngIf="preRegisterForm.controls.dateOfVisit.errors?.required">Start Date Required</mat-error>
                <mat-error *ngIf="preRegisterForm.controls.dateOut.errors?.required">End Date Required</mat-error>
            </mat-form-field>

            <!-- <div class="col-md-2 col-lg-2 netc-date-white-space mt-2"> -->
            <!-- <mat-form-field appearance="outline">
          <mat-label>From Date</mat-label>
          <mat-date-range-input [rangePicker]="picker">
          <input matStartDate  placeholder="dd-mm-yyyy" required formControlName="dateOfVisit"
            [max]="today" (ngModelChange)="onFromDate()" readonly>
            <input matEndDate  placeholder="dd-mm-yyyy" required formControlName="dateOut"
            [max]="restrictMinDate<today?restrictMinDate:today" (ngModelChange)="onToDate()" [min]="minDate" readonly>
          </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->
            <!-- <mat-form-field appearance="outline">
          <mat-label>To Date</mat-label>
          <input matInput [matDatepicker]="picker2" placeholder="dd-mm-yyyy" required formControlName="dateOfVisit"
            [max]="restrictMinDate<today?restrictMinDate:today" (ngModelChange)="onToDate()" [min]="minDate" readonly>
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>

        </mat-form-field> -->


        </mat-dialog-content>
        <mat-dialog-actions class="d-flex align-items-center justify-content-center">
            <!-- <button class="close" mat-button mat-raised-button mat-dialog-close>Cancel</button> -->
            <button type="submit" class="update" mat-button mat-raised-button
                [disabled]="preRegisterForm.invalid">Update</button>
        </mat-dialog-actions>
    </form>
</div>