

<mat-dialog-content class="dialog">
    <div class="content">
     <div class="heading text-center">
       <p class="mb-0">You are about to </p>
        <strong>sign In visitors</strong>      
     </div>
     <div class="subHeading text-center mt-3 mb-1">
       <p >Do you want to continue?</p>
     </div>
    </div>
    <div class="d-flex align-items-center justify-content-center mt-5 mb-2">
      <button type="button" mat-button id="closeSignOut" mat-raised-button mat-dialog-close>Close</button>
      <button type="submit"  mat-button id="signOutButton" mat-raised-button (click)="onSubmit()">SignIn</button>
    </div>
  <!-- </form> -->
  </mat-dialog-content>