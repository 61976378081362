<mat-dialog-content class="dialog">
  <div class="content">
    <div class="heading text-center">
      <p class="mb-0">You are about to</p>
      <strong>Archive {{editData.length}} Employee</strong>
    </div>
    <div class="subHeading text-center mt-3">
      <p>This action may Take a few minutes.</p>
    </div>
  </div>
  <div mat-dialog-actions class="buttons text-center">
    <button mat-button mat-dialog-close class="cancel" (click)="cancle()">Cancel</button>
    <button mat-button mat-dialog-signin class="archive" (click)="archive()">Archive</button>
  </div>
</mat-dialog-content>