<div style="float: right;cursor: pointer;">
    <button class="deleteIcon" mat-dialog-close> <i class="fas fa-times"></i></button>
</div>

<h2 mat-dialog-title>Add Employee</h2>
<mat-dialog-content class="mat-typography">
    <form class="mb-5" [formGroup]="employeeForm" (ngSubmit)="addEmployee()">
        <mat-form-field class="example-full-width">
            <mat-label>First name *</mat-label>
            <input type="text" matInput placeholder="First name" formControlName="fullName">
        </mat-form-field>
        <p *ngIf="( employeeForm.controls.fullName.touched) && employeeForm.controls.fullName.errors?.required"
            class="text-danger">
            First name is required.
        </p>
        <p *ngIf="employeeForm.get('fullName').touched && (employeeForm.get('fullName').errors?.pattern)"
            class="text-danger">First name accepts only alphabets!</p>


        <div class="text-danger"
            *ngIf="employeeForm.get('fullName').touched && employeeForm.get('fullName').errors?.minlength || employeeForm.get('fullName').errors?.maxlength">
            <p style="font-size: 14px;">
                First name accepts between 3-30 alphabets
            </p>
        </div>
        <!-- <p *ngIf="employeeForm.get('fullName').touched && (employeeForm.get('fullName').errors?.pattern || employeeForm.get('fullName').errors?.minlength)"
            class="text-danger">This is invalid name</p> -->

        <mat-form-field class="example-full-width">
            <mat-label>Last name *</mat-label>
            <input type="text" matInput placeholder="Last name" formControlName="lastName">
        </mat-form-field>
        <p *ngIf="(employeeForm.controls.lastName.touched) && employeeForm.controls.lastName.errors?.required"
            class="text-danger">
            Last name is required.
        </p>
        <p *ngIf="employeeForm.get('lastName').touched && (employeeForm.get('lastName').errors?.pattern)"
            class="text-danger">Last name accepts only alphabets!</p>


        <div class="text-danger"
            *ngIf="employeeForm.get('lastName').touched && employeeForm.get('lastName').errors?.minlength || employeeForm.get('lastName').errors?.maxlength">
            <p style="font-size: 14px;">
                Last name accepts between 1-30 alphabets
            </p>
        </div>

        <mat-form-field class="example-full-width">
            <mat-label>Email *</mat-label>
            <input type="email" matInput placeholder="Enter Email" formControlName="email">
        </mat-form-field>
        <p *ngIf="( employeeForm.controls.email.touched) && employeeForm.controls.email.errors?.required"
            class="text-danger">
            Email is required.
        </p>
        <p *ngIf="employeeForm.get('email').touched && (employeeForm.get('email').errors?.pattern || employeeForm.get('email').errors?.minlength)"
            class="text-danger">This is invalid Email</p>

        <mat-form-field class="example-full-width phone " floatLabel="always">
            <mat-label class="pho">Phone number *</mat-label>

            <input type="tel" matInput value="+{{countryCode}}" ng2TelInput (countryChange)="onCountryChange($event)"
                formControlName="phone">
        </mat-form-field>
        <p *ngIf="( employeeForm.controls.phone.touched) && employeeForm.controls.phone.errors?.required"
            class="text-danger">
            Phone number is required.
        </p>
        <p *ngIf="employeeForm.get('phone').touched && (employeeForm.get('phone').errors?.pattern || employeeForm.get('phone').errors?.minlength)"
            class="text-danger">This is invalid Phone number</p>

        <mat-form-field>
            <mat-label>Location *</mat-label>
            <mat-select disableRipple formControlName="location">
                <mat-option *ngFor="let location of deviceLocation" value="{{location.locationId}}" selected>
                    {{location.officeName}}</mat-option>
            </mat-select>
        </mat-form-field>
        <p *ngIf="( employeeForm.controls.location.touched) && employeeForm.controls.location.errors?.required"
            class="text-danger">
            Location is required.
        </p>


        <mat-form-field class="example-full-width">
            <mat-label>Assistant E-mail(Cc)</mat-label>
            <input type="email" matInput placeholder="email" formControlName="assistantEmail">
        </mat-form-field>
        <p *ngIf="( employeeForm.controls.assistantEmail.touched) && employeeForm.controls.assistantEmail.errors?.required"
            class="text-danger">
            assistantEmail is required.
        </p>
        <p *ngIf="employeeForm.get('assistantEmail').touched && (employeeForm.get('assistantEmail').errors?.pattern || employeeForm.get('assistantEmail').errors?.minlength)"
            class="text-danger">This is invalid assistantEmail</p>


        <mat-form-field class="example-full-width phone" floatLabel="always">
            <mat-label class="pho">Assist SMS(Ph no.)</mat-label>

            <input type="tel" matInput value="+{{countryCode1}}" ng2TelInput (countryChange)="onCountryChange1($event)"
                formControlName="assistSms" />
        </mat-form-field>
        <p *ngIf="( employeeForm.controls.assistSms.touched) && employeeForm.controls.assistSms.errors?.required"
            class="text-danger">
            assistSms is required.
        </p>
        <p *ngIf="employeeForm.get('assistSms').touched && (employeeForm.get('assistSms').errors?.pattern || employeeForm.get('assistSms').errors?.minlength)"
            class="text-danger">This is invalid assistSms</p>

        <div *ngIf="displayPlan && employeeSetting">
            <mat-form-field>
                <mat-label>Working Remotely</mat-label>
                <mat-select disableRipple formControlName="isRemoteUser">
                    <mat-option value="true" selected> Enable</mat-option>
                    <mat-option value="false" selected> Disable</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngFor="let form_elem of formTemplate">
            <div [ngSwitch]="form_elem.fieldType">
                <div *ngSwitchCase="'text'">
                    <div>
                        <mat-form-field class="example-full-width phone">
                            <mat-label class="pho">{{form_elem.label}}</mat-label>
                            <input type="tel" matInput formControlName="{{form_elem.label}}" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-center mb-2">
            <!-- <button class="close" mat-button mat-raised-button mat-dialog-close>Cancel</button> -->
            <button type="submit" class="add ml-2" [disabled]="employeeForm.invalid" mat-button
                mat-raised-button>Add</button>
        </div>
    </form>
</mat-dialog-content>