<!-- <p>payment-success works!</p> -->

<div class="text-center">
  <h2>Payment Successfull</h2>
  <h3>Thank you for Subscribing with breazie</h3>
  <div class="check">
    <!-- <img src="../../../assets/images/payment_success.gif" alt="" height="30px" width="30px"> -->
  </div>
  <div>
    <button type="button" mat-raised-button matDialogClose class="btn btn-success" 
    (click)="confirm()">Back to Dashboard</button>
  </div>
</div>
