<div class="text-center">
    <h2>Payment Transaction Failed</h2>
    <div class="check">

    </div>
    <div class="d-flex align-items-center justify-content-between mb-2" mat-dialog-actions>
        <button type="submit" class="btn btn-secondary" mat-button mat-dialog-close (click)="confirmFailed()">Back</button>
        <button type="submit" class="btn btn-danger" mat-button mat-dialog-close (click)="confirmFailed()">Cancel</button>
    </div>
    <!-- <mat-dialog-actions class="d-flex align-items-center justify-content-between mb-2">
        <button type="submit" class="btn btn-secondary" mat-button [mat-dialog-close]="true" (click)="confirmFailed()">Back</button>
        <button type="submit" class="btn btn-danger" mat-button [mat-dialog-close]="true" (click)="confirmFailed()">Cancel</button>
    </mat-dialog-actions> -->
</div>