<!-- <div mat-dialog-title>Dialog with elements</div> -->
<mat-dialog-content class="dialog">
    <div class="content">
     <div class="heading text-center">
       <p class="mb-0">Do you want to access free trial</p>  
     </div>
     <div class="subHeading text-center mt-3">
       <p>Do you want to continue?</p>
     </div>
    </div>
  <div mat-dialog-actions class="buttons text-center">
    <button mat-button mat-dialog-close class="cancel" (click)="cancle()">Cancel</button>
     <button mat-button mat-dialog-signin class="signin" (click)="accept()">Free Trial</button>
  
  </div>
  </mat-dialog-content>
  
  